
import BaseIcon from '~/components/BaseIcon.vue';

export default {
  name: 'TetrisHeadlineComponent',
  components: {BaseIcon},
  props: {
    headline: {
      type: String,
    },
    subheadline: {
      type: String,
    },
    tetrisImg: {
      type: Boolean,
    },
    headlineClasses: {
      type: String,
    },
  },
};
